import React, { useEffect, useState } from "react"
import styled from "styled-components"
import atob from "atob"
import swal from "sweetalert"
import { useSelector } from "react-redux"
import { getUsers, updateProfile } from "../../../../../Services/usersService"
import Icons from "../../../../../Pages/Icons/Examples/icons"
import MapLocationPickerModal from "../../../../../Pages/Components/mapLocationPickerModal"
import StyledDropDown from "../../../../../Pages/Components/styledDropDown"
import StyledInput from "../../../../../Pages/Components/styledInput"
import Button from "../../../../../Pages/Components/button"
import CheckBox from "../../../../../Pages/Components/checkBoxButton"
import Loader from "../../../../../Pages/Components/loader"
import { H5 } from "../../../../../Pages/Components/font"
import gps from "../../../../../Assets/images/gps.png"

const Wrapper = styled.div`
  width: 100%;
`

const BodyWrapper = styled.div`
  padding: 10px 20px;
`

const Header = styled.div`
  width: 100%;
  background: ${({ background }) => background};
  padding: 16px 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  img {
    height: 80px;
  }
`

const Row = styled.div`
  display: flex;
  > :last-child {
    margin-left: 1rem;
  }
`

const Card = styled.div`
  padding: 10px 20px;
  flex: 1;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  margin-top: 1.6rem;
  p {
    margin-left: 10px;
    margin-bottom: 0;
  }

  position: relative;
  > div {
    margin-top: 6px;
    + svg {
      position: absolute;
      right: 36px;
      top: 26px;
    }
  }
  svg {
    min-width: 24px;
    height: 24px;
  }
`

const CheckBoxWrapper = styled.div`
  display: flex;
  // justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1.6rem;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 2rem;
  > div {
    margin-bottom: 10px;
    margin-right: 6px;
  }
`

const dateListObject = {
  "d-m-Y": { label: "dd-mm-yyyy", id: 1 },
  "m-d-Y": { label: "mm-dd-yyyy", id: 2 },
  "Y-m-d": { label: "yyyy-mm-dd", id: 3 },
}

const Profile = ({ t }) => {
  const headerColor = useSelector(({ uiReducer }) => uiReducer.headerColor)
  const [loader, setLoader] = useState(false)
  const [isOpenMapModal, setIsOpenMapModal] = useState(false)
  // const [mapDefPosition, setMapDefPosition] = useState()
  const [dateFormatObject, setDateFormatObject] = useState({
    label: "dd-mm-yyyy",
    id: 1,
  })
  const [user, setUser] = useState({
    userName: "",
    phone: "",
    email: "",
    companyName: "",
    address: "",
    map_zoom_level: 15,
    page_refresh_interval: 10,
    map_default_position: "20.087463, 34.536283",
    colPermissionObject: {},
  })

  const [columnPermissions, setColumnPermissions] = useState({
    gps_odometer: 0,
    device_tag: 0,
    fuel: 0,
    driver_name: 0,
    driver_mobile: 0,
    temperature: 0,
    humidity: 0,
    battery: 0,
    status_total_time: 0,
    alert_sound: 0,
    permission_distance: 0,
    device_licence_validity: 0,
    installation_date: 0,
    sensor: 0,
    alertpriority_layout: 0,
    RS232_data: 0,
  })

  useEffect(() => {
    let isCancel = false

    const getProfile = async () => {
      try {
        const userName = localStorage.getItem("user_name")
        const key = localStorage.getItem("key")
        const userId = localStorage.getItem("user_id")
        const groupId = localStorage.getItem("group_id")
        const { data } = await getUsers(
          userName,
          key,
          groupId,
          "",
          "",
          "",
          "",
          "",
          userId
        )
        if (data.data && data.data[0]) {
          const {
            username: userName,
            company_name: companyName,
            company_address: address,
            page_refresh_interval,
            map_default_position,
            map_zoom_level,
            format_date,
            phone,
            email,
            column_permissions,
          } = data.data[0]

          const colPermissionObject = {}
          let colPermissionArray = column_permissions.split(";")

          colPermissionArray.forEach((element, index, array) => {
            array[index] = element.split("=")
            if (array[index][1] && parseInt(array[index][1]) === 1) {
              let field = array[index][0]
              if (field === "fuel_level") field = "fuel"
              colPermissionObject[field] = parseInt(array[index][1])
            }
          })

          !isCancel &&
            setColumnPermissions({
              ...columnPermissions,
              ...colPermissionObject,
            })

          const dateFormat = format_date.split(" ")[0]
          !isCancel &&
            dateFormat !== "" &&
            setDateFormatObject(dateListObject[dateFormat])

          !isCancel &&
            setUser({
              ...user,
              userName,
              phone,
              email,
              companyName,
              address,
              page_refresh_interval,
              map_default_position,
              map_zoom_level,
              // format_date,
              colPermissionObject,
            })
        }
      } catch (ex) {
        console.log(ex)
      }
    }

    getProfile()

    return () => (isCancel = true)
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    setLoader(true)
    try {
      const group_id = localStorage.getItem("group_id")
      const user_id = localStorage.getItem("user_id")
      const user_type_id = localStorage.getItem("user_type_id")
      const { data } = await updateProfile(
        group_id,
        user_type_id,
        user_id,
        user.map_zoom_level,
        user.page_refresh_interval,
        dateFormatObject.id,
        user.map_default_position,
        columnPermissions
      )

      if (data.result === 0) {
        data.message && swal(data.message)
      } else {
        swal("Oops!", `${t("USER_NOT_SAVED")}😐`)
      }
      setLoader(false)
    } catch (ex) {
      console.log("ex: ", ex)
      setLoader(false)
      swal(t("UNEXPECTED_ERROR"))
    }
  }

  const handleCheckBoxClick = (key) => {
    setColumnPermissions({
      ...columnPermissions,
      [key]: columnPermissions[key] === 1 ? 0 : 1,
    })
  }

  const handleLatLngSave = (latLng) => {
    setIsOpenMapModal(!isOpenMapModal)
    if (Array.isArray(latLng) && latLng.length < 2 && !latLng[0]) return

    setUser({
      ...user,
      map_default_position: `(${parseFloat(latLng[0]).toPrecision(
        8
      )},${parseFloat(latLng[1]).toPrecision(8)})`,
    })
  }

  const handleCloseModal = () => {
    setIsOpenMapModal(!isOpenMapModal)
  }

  return (
    <Wrapper>
      <Header background={headerColor}>
        <H5>{t("PROFILE")}</H5>
      </Header>

      <BodyWrapper>
        <Row>
          <Card>
            <Icons icon="user" fill="var(--success)" />
            <p>{user.userName}</p>
          </Card>
          <Card>
            <Icons icon="phone" fill="var(--red)" />
            <p>{user.phone}</p>
          </Card>
        </Row>
        <Row>
          <Card>
            <Icons icon="email" fill="var(--info)" />
            <p>{user.email}</p>
          </Card>
          <Card>
            <Icons icon="building" fill="var(--primary)" />
            <p>{user.companyName}</p>
          </Card>
        </Row>
        <Card>
          <Icons
            icon="distance"
            fill="var(--gray)"
            width="26px"
            height="26px"
          />
          <p>{atob(user.address)}</p>
        </Card>
        <form onSubmit={handleSubmit}>
          <Row>
            <Card>
              <StyledInput
                border
                type="text"
                placeholder="page refresh interval"
                value={user.page_refresh_interval}
                onChange={(e) =>
                  setUser({ ...user, page_refresh_interval: e.target.value })
                }
              />
            </Card>
            <Card>
              <StyledInput
                border
                type="text"
                placeholder="map zoom"
                value={user.map_zoom_level}
                onChange={(e) =>
                  setUser({ ...user, map_zoom_level: e.target.value })
                }
              />
            </Card>
          </Row>
          <Row>
            <Card>
              <StyledInput
                type="text"
                border
                placeholder="Map position"
                value={user.map_default_position}
                onClick={(e) => setIsOpenMapModal(!isOpenMapModal)}
                readOnly
              />
            </Card>
            <Card>
              <StyledDropDown
                name="userType"
                type="text"
                placeholder={t("DATE_FORMAT")}
                value={dateFormatObject.label}
                list={[
                  { label: "dd-mm-yyyy", id: 1 },
                  { label: "mm-dd-yyyy", id: 2 },
                  { label: "yyyy-mm-dd", id: 3 },
                ]}
                onClick={(item) => {
                  setDateFormatObject(item)
                }}
                onBlur={(e) => console.log("Blur: ", e)}
              />
            </Card>
          </Row>
          <CheckBoxWrapper>
            <CheckBox
              label="fuel"
              active={columnPermissions.fuel === 1}
              onClick={() => handleCheckBoxClick("fuel")}
            />
            <CheckBox
              label="Driver Name"
              active={columnPermissions.driver_name === 1}
              onClick={() => handleCheckBoxClick("driver_name")}
            />
            <CheckBox
              label="Driver Mobile"
              active={columnPermissions.driver_mobile === 1}
              onClick={() => handleCheckBoxClick("driver_mobile")}
            />
            <CheckBox
              label="Humidity"
              active={columnPermissions.humidity === 1}
              onClick={() => handleCheckBoxClick("humidity")}
            />
            <CheckBox
              label="Halt Time"
              active={columnPermissions.status_total_time === 1}
              onClick={() => handleCheckBoxClick("status_total_time")}
            />
            <CheckBox
              label="Alert Sound"
              active={columnPermissions.alert_sound === 1}
              onClick={() => handleCheckBoxClick("alert_sound")}
            />
            <CheckBox
              label="Distance"
              active={columnPermissions.permission_distance === 1}
              onClick={() => handleCheckBoxClick("permission_distance")}
            />
            <CheckBox
              label="Sensor"
              active={columnPermissions.sensor === 1}
              onClick={() => handleCheckBoxClick("sensor")}
            />
            <CheckBox
              label="Installation Date"
              active={columnPermissions.installation_date === 1}
              onClick={() => handleCheckBoxClick("installation_date")}
            />
            <CheckBox
              label="RS-232"
              active={columnPermissions.RS232_data === 1}
              onClick={() => handleCheckBoxClick("RS232_data")}
            />
            <CheckBox
              label="Billing Validity"
              active={columnPermissions.device_licence_validity === 1}
              onClick={() => handleCheckBoxClick("device_licence_validity")}
            />
            <CheckBox
              label="Alert Priority"
              active={columnPermissions.alertpriority_layout === 1}
              onClick={() => handleCheckBoxClick("alertpriority_layout")}
            />
            <CheckBox
              label="Gps Odometer"
              active={columnPermissions.gps_odometer === 1}
              onClick={() => handleCheckBoxClick("gps_odometer")}
            />
            <CheckBox
              label="Device Tag"
              active={columnPermissions.device_tag === 1}
              onClick={() => handleCheckBoxClick("device_tag")}
            />
            <CheckBox
              label="Temperature"
              active={columnPermissions.temperature === 1}
              onClick={() => handleCheckBoxClick("temperature")}
            />
          </CheckBoxWrapper>
          <Button color="primary" type="submit" label={t("UPDATE")} />
        </form>
        {loader && <Loader />}
        {isOpenMapModal && (
          <MapLocationPickerModal
            markerLatLng={[
              user.map_default_position.substr(1, 9),
              user.map_default_position.substr(-10, 9),
            ]}
            onCloseModal={handleCloseModal}
            onSave={handleLatLngSave}
          />
        )}
      </BodyWrapper>
    </Wrapper>
  )
}

export default Profile
