function getBaseLayers(L) {
  const map_type = localStorage.getItem("map_type")
  let mapRoadMap = null
  let mapSatelite = null
  // let mapTraffic = null
  if (map_type === "googlev3") {
    //mapRoadMap 		= new L.Google('ROADMAP'); // Possible types: SATELLITE, ROADMAP, HYBRID, TERRAIN
    //mapSatelite 	= new L.Google('SATELLITE'); // Possible types: SATELLITE, ROADMAP, HYBRID, TERRAIN
    //mapTraffic = new  L.Google('TRAFFIC');

    mapRoadMap = L.tileLayer(
      "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
      {
        maxZoom: 18,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }
    )

    mapSatelite = L.tileLayer(
      "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
      {
        maxZoom: 18,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }
    )

    // mapTraffic = L.tileLayer(
    //   "http://{s}.google.com/vt/lyrs=m,traffic&x={x}&y={y}&z={z}",
    //   {
    //     maxZoom: 18,
    //     subdomains: ["mt0", "mt1", "mt2", "mt3"],
    //   }
    // )
  } else {
    //(map_type === "openstreet")
    mapRoadMap = L.tileLayer(
      "https://map.nms.co.in/osm_tiles/{z}/{x}/{y}.png",
      { maxZoom: 18 }
    )
    mapSatelite = L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw",
      {
        maxZoom: 18,
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
          '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
          'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        id: "mapbox/satellite-streets-v9",
      }
    )

    // mapTraffic = L.tileLayer(
    //   "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw",
    //   {
    //     maxZoom: 18,
    //     attribution:
    //       'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
    //       '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
    //       'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    //     id: "mapbox/streets-v11",
    //   }
    // )
  }

  return {
    Map: mapRoadMap,
    Satelite: mapSatelite,
    // Traffic: mapTraffic,
  }
}

export default getBaseLayers
