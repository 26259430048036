import React from "react"
import { connect } from "react-redux"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import HeaderRightDrawer from "./Components/HeaderRightDrawer"
import styled from "styled-components"
import Icons from "../../Pages/Icons/Examples/icons"

const ContainerSlider = styled.div`
  > div:first-child {
    padding-right: 0 !important;
  }
`

const Dot = styled.div`
  width: 18px;
  font-size: 10px;
  font-weight: 600;
  color: var(--danger);
  text-align: center;
  position: absolute;
  right: 5px;
  top: 5px;
`

const Header = ({ alertNotification }) => (
  <ReactCSSTransitionGroup
    component="div"
    transitionName="HeaderAnimation"
    transitionAppear={true}
    transitionAppearTimeout={1500}
    transitionEnter={false}
    transitionLeave={false}
  >
    <div className="app-header-right">
      <ContainerSlider>
        <HeaderRightDrawer
          hamburgerClass="left-header-btn-lg"
          hamBurger={true}
          left="true"
        />
      </ContainerSlider>
    </div>
    <div>
      <HeaderRightDrawer hamburgerClass="d-none" right="true">
        <div className="right-header-btn-lg fsize-3 mb-0">
          <Icons icon="alertOutline" fill="var(--danger)" />
          <Dot>
            {alertNotification && alertNotification.length > 0
              ? alertNotification.length
              : 0}
          </Dot>
        </div>
      </HeaderRightDrawer>
    </div>
  </ReactCSSTransitionGroup>
)

const isEqual = (prevProps, nextProps) => {
  const { alertNotification: prevAlertNotification } = prevProps
  const { alertNotification: nextAlertNotification } = nextProps
  return prevAlertNotification &&
    prevAlertNotification.length !== nextAlertNotification.length
    ? false
    : true
}

const MemoHeader = React.memo(Header, isEqual)

const mapStateToProps = (state) => ({
  alertNotification: state.alertNotification.alertNotification,
})

export default connect(mapStateToProps)(MemoHeader)
