import React, { Suspense } from "react"
import styled from "styled-components"
import Hamburger from "react-hamburgers"
import PerfectScrollbar from "react-perfect-scrollbar"
import Drawer from "react-motion-drawer"
import RightDrawer from "./TabsContent/rightDrawer"

const DrawerWrapper = styled.div`
  > div {
    padding: 0 !important;
  }
`

const RightSideBar = ({ openRight, onChangeOpenRight }) => (
  <Suspense fallback={<>.</>}>
    <DrawerWrapper>
      <Drawer
        right
        className="drawer-content-wrapper p-0"
        width={350}
        open={openRight}
        onChange={(open) => onChangeOpenRight(open)}
        noTouchOpen={false}
        noTouchClose={false}
      >
        <PerfectScrollbar>
          <div className="drawer-nav-btn drawer-nav-btn_right">
            <Hamburger
              active={openRight}
              type="elastic"
              onClick={() => onChangeOpenRight(false, false)}
            />
          </div>
          <RightDrawer />
        </PerfectScrollbar>
      </Drawer>
    </DrawerWrapper>
  </Suspense>
)

export default RightSideBar
