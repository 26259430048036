import React from "react"
import styled from "styled-components"

const Button = styled.button`
  letter-spacing: 1.2px;
  color: ${({ background }) => background && "#fff !important"};
  background: ${({ background }) => `${background} !important`};
`

export default ({ label, color, ...restProps }) => (
  <Button
    className={`btn btn-pill btn-outline-${color}`}
    type="button"
    {...restProps}
  >
    {label}
  </Button>
)
