import React from "react"
import ReactDOM from "react-dom"
// import "leaflet"
import App from "./app"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./Assets/base.scss"
import "./i18n"

const rootElement = document.getElementById("root")

const renderApp = (Component) =>
  ReactDOM.render(
    <BrowserRouter>
      <Component />
    </BrowserRouter>,
    rootElement
  )

renderApp(App)

if (module.hot) {
  module.hot.accept("./app", () => {
    const NextApp = require("./app").default
    renderApp(NextApp)
  })
}

serviceWorker.register()
// "postbuild": "gzip build/static/js/*.js && gzip build/static/css/*.css",
// "build": "react-scripts build && gzipper --verbose ./build",
