import http from "./httpService"

// "baseUrl": "https://track.georadius.in/",

export const getVehicle = (user_id) => {
  const user_name = localStorage.getItem("user_name")
  const hash_key = localStorage.getItem("key")
  return http.post("tracking_api.php", {
    action: "track",
    user_id,
    user_name,
    hash_key,
  })
}

// export const getVehicle = (hash_key, user_name, user_id) => {
//   return http.get("tracking_data.php", {
//     params: {
//       show_stopped: true,
//       show_idling: true,
//       show_moving: true,
//       show_inactive: true,
//       show_unreachable: true,
//       show_alert: false,
//       action: "json",
//       user_name,
//       hash_key,
//       user_id
//     }
//   })
// }

export const getVehicleLocDis = () => {
  const user_name = localStorage.getItem("user_name")
  const hash_key = localStorage.getItem("key")
  return http.post("tracking_api.php", {
    action: "extra",
    user_name,
    hash_key,
  })
}

export const getAllVehicle = (
  user_name,
  hash_key,
  reg_no,
  reg_date,
  device_serial,
  sim_no,
  voice_no,
  device_tag,
  chassis_no,
  device_type,
  user_id,
  vehicle_type = null
) =>
  http.get("vehicle_result.php", {
    params: {
      data_format: "1",
      action: "search",
      user_id,
      reg_no,
      reg_date,
      device_type,
      voice_no,
      device_serial,
      sim_no,
      vehicle_type,
      chassis_no,
      device_tag,
      user_name,
      hash_key,
    },
  })

export const getUserVehicles = (user_name, hash_key, user_id) =>
  http.get("vehicle_result.php", {
    params: {
      data_format: "1",
      action: "search",
      user_id,
      user_name,
      hash_key,
    },
  })

export const getVehicleHistory = (device_id, date_from, date_to) => {
  return http.get("report_map_history_result.php", {
    params: {
      action: "map_history_json",
      date_from,
      date_to,
      device_id,
    },
  })
}

export const getVehicleTrip = (
  hash_key,
  user_name,
  device_id,
  date_from,
  date_to,
  time_picker_from,
  time_picker_to
) => {
  return http.get("report_trip_result.php", {
    params: {
      action: "report_trip_json",
      idling: 1,
      moving: 1,
      stoppage: 1,
      unreach: 1,
      date_from,
      date_to,
      time_picker_from,
      time_picker_to,
      device_id,
      user_name,
      hash_key,
    },
  })
}

export const getVehicleMovingTrip = (
  hash_key,
  user_name,
  device_id,
  start_date,
  end_date
) => {
  return http.get("report_trip_result.php", {
    params: {
      data_format: "json",
      action: "trip_moving",
      device_id,
      start_date,
      end_date,
      user_name,
      hash_key,
    },
  })
}

export const getVehicleDistance = (
  device_id, 
  start_date,
  end_date,
  time_picker_from,
  time_picker_to,
  method_type
) => {
  return http.get("report_vehicle_distance_result.php", {
    params: {
      data_format: "json",
      device_id,
      date_from: start_date,
      date_to: end_date,
      time_picker_from,
      time_picker_to,
      distance_covered_unit: 0,
      method_type: 2,
      group_hour: 24,
      group_mode: 0,
    },
  })
}

export const postVehicleDistance = (
  hash_key,
  user_name,
  device_id,
  start_date,
  end_date,
  time_picker_from,
  time_picker_to,
  method_type
) => {
  return http.get("report_vehicle_distance_result.php", {
    params: {
      data_format: "json",
      date_from: start_date,
      date_to: end_date,
      time_picker_from,
      time_picker_to,
      distance_covered_unit: 0,
      method_type: 2,
      group_hour: 24,
      group_mode: 1,
      user_name,
      hash_key,
      device_id,
    },
  })
}

export const getAlerts = (
  hash_key,
  user_name,
  device_id,
  date_from,
  date_to,
  time_picker_from,
  time_picker_to,
  alert_type_id
) => {
  return http.get("report_alert_result.php", {
    params: {
      action: "report_alert_json",
      date_from,
      date_to,
      time_picker_from,
      time_picker_to,
      alert_type_id,
      order_by: 3,
      order_type: 0,
      user_name,
      hash_key,
      device_id,
    },
  })
}

export const getFleetUsage = (
  device_id,
  date_from,
  date_to,
  time_picker_from,
  time_picker_to
) => {
  return http.get("report_fleet_usage_result.php", {
    params: {
      action: "report_fleet_json",
      date_from,
      date_to,
      time_picker_from,
      time_picker_to,
      device_id,
    },
  })
}

export const getOverSpeed = (
  // hash_key,
  // user_name,
  device_id,
  date_from,
  date_to,
  time_picker_from,
  time_picker_to,
  speed_limit
) => {
  return http.get("report_over_speed_result.php", {
    params: {
      action: "report_overspeed_json_new",
      date_from,
      date_to,
      time_picker_from,
      time_picker_to,
      speed_limit,
      // user_name,
      // hash_key,
      device_id,
    },
  })
}

export const getVehicleFuel = (
  device_id,
  date_from,
  date_to,
  time_picker_from,
  time_picker_to
) => {
  return http.get("report_fuel_result.php", {
    params: {
      action: "fuel_graph",
      data_format: "json",
      date_from,
      date_to,
      time_picker_from,
      time_picker_to,
      device_id,
    },
  })
}

export const getVehicleTemp = (
  hash_key,
  user_name,
  device_id,
  date_from,
  date_to,
  time_picker_from,
  time_picker_to
) => {
  return http.get("report_fuel_result.php", {
    params: {
      action: "fuel_graph",
      data_format: "json",
      date_from,
      date_to,
      time_picker_from,
      time_picker_to,
      user_name,
      hash_key,
      device_id,
    },
  })
}

export const getVehicleTripIgnition = (
  hash_key,
  user_name,
  device_id,
  date_from,
  date_to,
  time_picker_from,
  time_picker_to
) => {
  return http.get("report_trip_ignition_result.php", {
    params: {
      action: "json",
      date_from,
      date_to,
      time_picker_from,
      time_picker_to,
      user_name,
      hash_key,
      device_id,
    },
  })
}

export const getVehicleDigitalSensor = (
  device_id,
  date_from,
  date_to,
  time_picker_from,
  time_picker_to,
  sensor_type,
  sensor_value
) =>
  http.get("report_sensor_result.php", {
    params: {
      data_format: 1,
      date_from,
      date_to,
      time_picker_from,
      time_picker_to,
      device_id,
      sensor_type,
      sensor_value,
      order_by: 0,
      order_type: 0,
    },
  })

export const getVehicleSearchLayout = (user_name, hash_key) =>
  http.get("misc_settings_results.php", {
    params: {
      action: "display_settings",
      data_format: 1,
      module_code: "LAYOUT",
      setting_name: "vehicle_search_layout",
      user_name,
      hash_key,
    },
  })

export const getVehicleType = (user_name, hash_key) =>
  http.get("vehicle_type_result.php", {
    params: {
      action: "display",
      data_format: 1,
      user_name,
      hash_key,
    },
  })

export const getVehicleBrand = (user_name, hash_key) =>
  http.get("vehicle_type_result.php", {
    params: {
      action: "display_brand",
      user_name,
      hash_key,
    },
  })

export const getVehicleMake = (vehiclebrand_id, user_name, hash_key) =>
  http.get("vehicle_type_result.php", {
    params: {
      action: "display_vehiclemake",
      vehiclebrand_id,
      user_name,
      hash_key,
    },
  })

export const getVehicleModel = (vehiclemake_id, user_name, hash_key) =>
  http.get("vehicle_type_result.php", {
    params: {
      action: "display_vehiclemodel",
      vehiclemake_id,
      user_name,
      hash_key,
    },
  })

export const updateVehicle = (device_id, paramsObj, user_name, hash_key) =>
  http.get("vehicle_result.php", {
    params: {
      action: "vehicleedit",
      data_format: 1,
      device_id,
      // last_update,
      user_name,
      hash_key,
      ...paramsObj,
    },
  })

export const dateResetVehicle = (device_id, user_name, hash_key) =>
  http.get("vehicle_result.php", {
    params: {
      action: "reset",
      data_format: 1,
      device_id,
      user_name,
      hash_key,
    },
  })

export const getVehicleUser = (device_id, user_name, hash_key) =>
  http.get("user_result.php", {
    params: {
      action: "vehicle_user",
      data_format: 1,
      device_id,
      user_name,
      hash_key,
    },
  })

export const getVehicleGroup = (device_id, user_name, hash_key) =>
  http.get("group_result.php", {
    params: {
      action: "vehicle_group",
      data_format: 1,
      device_id,
      user_name,
      hash_key,
    },
  })

export const removeVehicleGroup = (device_id, group_id, user_name, hash_key) =>
  http.get("vehicle_result.php", {
    params: {
      action: "unassign_group_devices",
      data_format: 1,
      device_id,
      group_id,
      user_name,
      hash_key,
    },
  })

export const removeVehicleUser = (device_id, user_id, user_name, hash_key) =>
  http.get("vehicle_result.php", {
    params: {
      action: "unassign_user_devices",
      data_format: 1,
      device_id,
      user_id,
      user_name,
      hash_key,
    },
  })

export const getUsersVehicleGroup = (user_id, user_name, hash_key) =>
  http.get("vehicle_result.php", {
    params: {
      action: "search",
      inverse_user: 1,
      data_format: 1,
      user_id,
      user_name,
      hash_key,
    },
  })

export const getUsersVehicleList = (user_id, user_name, hash_key) =>
  http.get("vehicle_result.php", {
    params: {
      action: "search",
      inverse_user: 0,
      data_format: 1,
      user_id,
      user_name,
      hash_key,
    },
  })

export const getLicense = (user_id) =>
  http.get("vehicle_result.php", {
    params: {
      action: "search_json",
      user_id,
    },
  })

export const getAlertNotification = (user_id) =>
  http.get("report_alert_result.php", {
    params: {
      action: "display_appalert",
      data_format: 1,
      user_id,
    },
  })

export const getDeviceLocation = (device_id, phone_val, validity_date) =>
  http.get("vehicle_result.php", {
    params: {
      action: "temp_access",
      data_format: 1,
      device_id,
      phone_val,
      validity_date,
      email_val: "",
    },
  })
