import * as type from "../types/types"

const initialState = {
  vehicleTracking: { vehicles: [] },
  usersVehicleTracking: { vehicles: [] },
  vehicleType: [],
  vehicleBrand: [],
  vehicleMake: [],
  vehicleDistance: {},
  vehicleAlerts: {},
  vehicleTripIgnition: {},
  vehicleTrip: {},
  vehicleOverSpeed: {},
  vehicleFuel: {},
  vehicleFleet: {},
  vehicleDigitalSensor: {},
  // alertNotification: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_VEHICLE:
      return {
        ...state,
        vehicleTracking: action.payload,
      }
    case type.FETCH_USERS_VEHICLE:
      return {
        ...state,
        usersVehicleTracking: action.payload,
      }
    case type.FETCH_VEHICLE_HISTORY:
      return {
        ...state,
        vehicleHistory: action.payload,
      }
    case type.CLEAR_VEHICLE_HISTORY:
      return {
        ...state,
        vehicleHistory: {},
      }
    case type.FETCH_VEHICLE_TRIP:
      return {
        ...state,
        vehicleTrip: action.payload,
      }
    case type.CLEAR_VEHICLE_TRIP:
      return {
        ...state,
        vehicleTrip: {},
      }
    case type.FETCH_VEHICLE_DISTANCE:
      return {
        ...state,
        vehicleDistance: action.payload,
      }
    case type.CLEAR_VEHICLE_DISTANCE:
      return {
        ...state,
        vehicleDistance: {},
      }
    case type.FETCH_VEHICLE_ALERTS:
      return {
        ...state,
        vehicleAlerts: action.payload,
      }
    case type.CLEAR_VEHICLE_ALERTS:
      return {
        ...state,
        vehicleAlerts: {},
      }
    case type.FETCH_VEHICLE_FLEET:
      return {
        ...state,
        vehicleFleet: action.payload,
      }
    case type.CLEAR_VEHICLE_FLEET:
      return {
        ...state,
        vehicleFleet: {},
      }
    case type.FETCH_VEHICLE_OVER_SPEED:
      return {
        ...state,
        vehicleOverSpeed: action.payload,
      }
    case type.CLEAR_VEHICLE_OVER_SPEED:
      return {
        ...state,
        vehicleOverSpeed: {},
      }
    case type.FETCH_VEHICLE_FUEL:
      return {
        ...state,
        vehicleFuel: action.payload,
      }
    case type.CLEAR_VEHICLE_FUEL:
      return {
        ...state,
        vehicleFuel: {},
      }
    case type.FETCH_VEHICLE_TRIP_IGNITION:
      return {
        ...state,
        vehicleTripIgnition: action.payload,
      }
    case type.CLEAR_VEHICLE_TRIP_IGNITION:
      return {
        ...state,
        vehicleTripIgnition: {},
      }
    case type.FETCH_VEHICLE_DIGITAL_SENSOR:
      return {
        ...state,
        vehicleDigitalSensor: action.payload,
      }
    case type.CLEAR_VEHICLE_DIGITAL_SENSOR:
      return {
        ...state,
        vehicleDigitalSensor: {},
      }
    case type.FETCH_VEHICLE_TYPE:
      return {
        ...state,
        vehicleType: action.payload,
      }
    case type.FETCH_VEHICLE_BRAND:
      return {
        ...state,
        vehicleBrand: action.payload,
      }
    case type.FETCH_VEHICLE_MAKE:
      return {
        ...state,
        vehicleMake: action.payload,
      }
    // case types.FETCH_ALERT_NOTIFICATION:
    //   return {
    //     ...state,
    //     alertNotification: action.payload
    //   }
    default:
      return {
        ...state,
      }
  }
}
