import React, { Suspense } from "react"
import FooterDots from "./Components/FooterDots"
import styled from "styled-components"

const Div = styled.div`
  background: #f1f4f6 !important;
`

const Loading = () => <p></p>

export default () => {
  return (
    <div className="fixed-footer">
      <div className="app-footer">
        <Div className="app-footer__inner">
          <div className="app-footer-center">
            <Suspense fallback={Loading()}>
              <FooterDots />
            </Suspense>
          </div>
        </Div>
      </div>
    </div>
  )
}
