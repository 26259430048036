import React, { useEffect, useRef, useState } from "react"
import L from "leaflet"
import "leaflet/dist/leaflet.css"
import { useSelector } from "react-redux"
import useMap from "../../utils/useMap"
import styled, { keyframes } from "styled-components"
import CloseX from "./closeX"
import Tick from "./tick"
import { H5 } from "./font"
import markerDef from "../../Assets/svgs/markerDef.svg"

const zoomIn = keyframes`
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
`

const ModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Modal = styled.div`
  width: 70vw;
  background: #fff;
  // border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
    0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
    0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  animation: ${zoomIn} 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
  > div:nth-child(2) {
    background: #fff;
    position: absolute;
    right: 10px;
    left: auto;
    z-index: 10000;
    margin-right: 0;
  }

  > div:nth-child(3) {
    background: #fff;
    position: absolute;
    right: 10px;
    top: 60px;
    left: auto;
    z-index: 10000;
    margin-right: 0;
  }
`

const Header = styled.div`
  width: 100%;
  background: ${({ background }) => background};
  padding: 16px 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
`

export default ({ markerLatLng, onCloseModal, onSave }) => {
  const [latLng, setLatLng] = useState([])
  const [mapMarker, setMapMarker] = useState(null)
  const [isMarkerAdded, setIsMarkerAdded] = useState(false)
  const onMapClickRef = useRef(null)
  const mapRef = useRef(null)
  const headerColor = useSelector(({ uiReducer }) => uiReducer.headerColor)

  const addMarker = (map) => {
    const carIcon = getLeafletIcon(markerDef)
    const marker = L.marker(markerLatLng, {
      icon: carIcon,
    })
    setMapMarker(marker)
    marker.addTo(map)
    // console.log("addMarker: ", markerLatLng)
  }

  // mapRef.current = useMap(mapDefPos, L, addMarker)
  mapRef.current = useMap(markerLatLng, L, addMarker)
  const mapMarkerRef = useRef(null)

  const getLeafletIcon = (carIcon) => {
    const iconSize = carIcon === markerDef && new L.Point(35, 35)
    return new L.Icon({
      iconUrl: carIcon,
      iconRetinaUrl: carIcon,
      iconAnchor: [18, 30],
      popupAnchor: [0, -10],
      shadowUrl: null,
      shadowSize: [68, 68],
      shadowAnchor: [4, 42],
      iconSize,
    })
  }

  onMapClickRef.current = isMarkerAdded
  mapMarkerRef.current = mapMarker

  const onMapClick = (e) => {
    mapMarkerRef.current !== null &&
      mapRef.current.removeLayer(mapMarkerRef.current)

    const carIcon = getLeafletIcon(markerDef)
    const marker = L.marker([e.latlng.lat, e.latlng.lng], {
      icon: carIcon,
    })
    marker.addTo(mapRef.current)
    setMapMarker(marker)
    setLatLng([e.latlng.lat, e.latlng.lng])
  }

  useEffect(() => {
    mapRef.current && mapRef.current.on("click", onMapClick)
  }, [mapRef.current])

  return (
    <ModalWrapper>
      <Modal>
        <Header background={headerColor}>
          <H5>Map Default Position</H5>
        </Header>
        <CloseX onClick={onCloseModal} />
        <Tick onClick={() => onSave(latLng)} />
        <div
          id="map"
          style={{
            width: "70vw",
            height: "70vh",
          }}
        />
      </Modal>
    </ModalWrapper>
  )
}
