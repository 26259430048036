import React from "react"
import { DropdownItem, Popover } from "reactstrap"
// import Flag from "react-flagkit"
import city3 from "../../../Assets/utils/images/dropdown-header/city3.jpg"

export default ({ t, pagesAuth, toggle, popoverOpen, onRedirectClick }) => {
  const renderDropDownItem = (toggle, dataAttr = "us", label, value) => (
    <DropdownItem
      toggle={toggle}
      data-lang={dataAttr.toLowerCase()}
      onClick={() => onRedirectClick(value.toLowerCase())}
    >
      {label}
    </DropdownItem>
  )

  return (
    <Popover
      className="popover-custom popover-custom-sm"
      trigger="legacy"
      hideArrow
      fade={false}
      placement="top"
      isOpen={popoverOpen}
      target="popoverOpenSetting"
      toggle={toggle}
    >
      <div className="dropdown-menu-header">
        <div className="dropdown-menu-header-inner py-2 bg-dark">
          <div
            className="menu-header-image opacity-1"
            style={{
              backgroundImage: "url(" + city3 + ")",
            }}
          />
          <div className="menu-header-content text-center text-white">
            <h6 className="menu-header-subtitle mt-0">{t("CHOOSE_SETTING")}</h6>
          </div>
        </div>
      </div>
      <div className="dropdown-menu-rounded pt-0">
        {renderDropDownItem(
          false,
          "us",
          t("NOTIFICATION_LABEL"),
          "notification"
        )}
        {pagesAuth["19"] &&
          renderDropDownItem(
            false,
            "us",
            t("RESET_PASSWORD"),
            "reset_password"
          )}
        {pagesAuth["20"] &&
          renderDropDownItem(
            false,
            "us",
            t("MAINTENANCE_RECORD"),
            "maintenance_record"
          )}
      </div>
    </Popover>
  )
}
