import styled from "styled-components"

const color1 = "#fff"

export const H5 = styled.h5`
  font-family: Roboto;
  font-size: 16px !important;
  font-weight: 400;
  color: ${color1};
  letter-spacing: 1.1px;
  text-transform: uppercase;
  margin-bottom: 0;
`

export const Para10 = styled.p`
  font-size: 10px;
  font-family: Roboto;
  letter-spacing: 0.6px;
  color: ${({ color }) => (color ? color : "var(--secondary)")};
  text-transform: ${({ textTransform }) => textTransform};
  margin-bottom: 0;
`
export const Para12 = styled.p`
  font-size: 12px;
  font-family: Roboto;
  font-weight: ${({ fontWeight }) => fontWeight};
  letter-spacing: 0.8px;
  color: ${({ color }) => (color ? color : "var(--secondary)")};
  text-transform: ${({ textTransform }) => textTransform};
  margin-bottom: 0;
`

export const Para14 = styled.p`
  font-size: 14px;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-family: Roboto;
  letter-spacing: 0.8px;
  white-space: nowrap;
  color: ${({ color }) => (color ? color : "var(--secondary)")};
  text-transform: ${({ textTransform }) =>
    textTransform ? textTransform : "uppercase"};
  margin-bottom: 0;
`

export const Para20 = styled.p`
  font-size: 20px;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-family: Roboto;
  letter-spacing: 0.8px;
  color: ${({ color }) => (color ? color : "var(--secondary)")};
  text-transform: ${({ textTransform }) => textTransform};
  margin-bottom: 0;
`
