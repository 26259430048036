import * as types from "../types/ui"

const initialState = {
  headerColor: "#3c8dbc",
  activeButton: "home"
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_HEADER_COLOR:
    case types.SET_ACTIVE_FOOTER_BTN:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
