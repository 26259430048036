import React, { Component } from "react"
import styled from "styled-components"
import onClickOutside from "react-onclickoutside"
import StyledInput from "./styledInput"

const FormGroup = styled.div`
  width: 100%;
  position: relative;
`

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`

const ListWrapper = styled.ul`
  //   width: 300px;
  width: 100%;
  max-height: 240px;
  background: var(--white);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
    0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
    0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
    0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
  position: absolute !important;
  // z-index: 100;
  z-index: 100000;
  // padding: 1rem 0 !important;
  padding: 0 !important;

  overflow-y: scroll;

  list-style: none;

  li {
    cursor: pointer;
    padding: 0 1rem;
    :hover {
      background: #e0f3ff;
    }
  }

  li:last-child {
    p {
      border-bottom: 0;
    }
  }
`

const Font14 = styled.p`
  font-size: 14px;
  padding: 6px 4px;
  margin-bottom: 0;
  border-bottom: 1px solid #dee2e6;
`

class DropDown extends Component {
  state = { isOpenList: false }

  handleClickOutside = (e) => {
    this.setState({ isOpenList: false })
  }

  render = () => {
    const {
      name,
      type,
      placeholder,
      value,
      active,
      handleOutSideClick,
      onClick,
      list, //contains label, id
      ...restProps
    } = this.props

    return (
      <FormGroup>
        <InputWrapper
          onClick={(e) => {
            this.setState({ isOpenList: true })
            e.preventDefault()
          }}
        >
          <StyledInput
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            icon1={{
              name: "arrowDown",
              width: "36px",
              height: "36px",
              fill: "darkcyan",
            }}
            // disabled
            border
            readOnly
            {...restProps}
          />
        </InputWrapper>
        {this.state.isOpenList && list.length > 0 && (
          <ListWrapper>
            {list.map((item) => (
              <li
                key={item.id}
                onClick={() => {
                  onClick(item)
                  this.setState({ isOpenList: false })
                }}
              >
                <Font14>{item.label}</Font14>
              </li>
            ))}
          </ListWrapper>
        )}
      </FormGroup>
    )
  }
}

export default onClickOutside(DropDown)
