import { LOGIN } from "../types/Authentication"

const initialLoginStore = {
  user_name: "",
  email: "",
  user_id: null,
  pagesAuth: {},
  functionAuth: {},
}

export default (state = initialLoginStore, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}
