import React from "react"
import styled, { css } from "styled-components"
import Icons from "../Icons/Examples/icons"

const flex_center_center = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const IconWrapper = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  ${flex_center_center};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  //================
  position: sticky;
  margin-right: -3rem;
  left: 100%;
  // position: absolute;
  // right: 10px;
  top: 10px;
  z-index: 100;
  cursor: pointer;
  transition: all 0.3s ease;

  :hover {
    transform: scale(1.2);
    background: rgba(0, 0, 0, 0.05);

    // svg {
    // path:last-child {
    // fill: var(--red);
    // }
    // }
  }
`

export default (props) => (
  <IconWrapper {...props}>
    <Icons icon="tick" fill="var(--gray)" />
  </IconWrapper>
)
