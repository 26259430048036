import { get, post, defaults, interceptors } from "axios"
import baseConfig from "../config"
// import baseConfig from "../config/defaultConfig.json"

defaults.baseURL = baseConfig.baseUrl
// defaults.headers.common["Authorization"] = AUTH_TOKEN
// defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded"

// Add a request interceptor
interceptors.request.use(
  function(config) {
    try {
      const user_name = localStorage.getItem("user_name")
      const hash_key = localStorage.getItem("key")

      if (config.method.toLowerCase() === "get")
        config.params = { user_name, hash_key, ...config.params }

      //location.href='login.html'; error in biing API if uncommented below code
      // const res =
      //   config.method.toLowerCase() === "post" &&
      //   !(
      //     config.headers["Content-Type"] &&
      //     config.headers["Content-Type"].indexOf("multipart/form-data") !== -1
      //   )

      // console.log(
      //   "res--",
      //   config.headers["Content-Type"] &&
      //     config.headers["Content-Type"].indexOf("multipart/form-data") !== -1
      // )

      // if (res) {
      //   config.data = {
      //     user_name,
      //     hash_key,
      //     ...config.data,
      //   }
      // }
      // console.log("config--", config)
    } catch (ex) {
      console.log(ex)
    }
    // Do something before request is sent
    // defaults.baseURL = baseConfig.baseURL
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

export default { get, post }
