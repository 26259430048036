import React, { lazy, useEffect, useState, Suspense } from "react"
import { Switch, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import LazyRoute from "./lazyRoute"
import PrivateRoute from "./privateRoute"
import { fetchAlertNotification } from "../../Store/actions/vehicleAction"
import Loader from "../../Pages/Components/loader"

const Live = lazy(() => import("../../Pages/UserPages/Live"))
const Login = lazy(() => import("../../Pages/UserPages/Login"))
const Reports = lazy(() => import("../../Pages/UserPages/Reports"))
const Settings = lazy(() => import("../../Pages/UserPages/Settings"))
const GeoFence = lazy(() => import("../../Pages/UserPages/GeoFence"))
const Users = lazy(() => import("../../Pages/UserPages/Users"))
const Billing = lazy(() => import("../../Pages/UserPages/Billing"))
const Vehicles = lazy(() => import("../../Pages/UserPages/Vehicles"))
const Dashboard = lazy(() => import("../../Pages/UserPages/Dashboard"))
const ShareLocation = lazy(() => import("../../Pages/UserPages/ShareLocation"))

const Loading = () => <Loader />

const AppMain = ({ pagesAuth, fetchAlertNotification }) => {
  const [isPool, setIsPool] = useState(false)

  const wait = () =>
    new Promise((resolve, reject) => setTimeout(() => resolve("relax"), 100000))

  const handleAlertNotification = async () => {
    const userId = localStorage.getItem("user_id")
    fetchAlertNotification(userId)
    await wait()
    setIsPool(!isPool)
  }

  useEffect(() => {
    if (
      !(
        window.location.pathname === "/" ||
        window.location.pathname === "/login"
      )
    )
      handleAlertNotification()
  }, [isPool])

  const renderRoute = () => (
    <Switch>
      <PrivateRoute path="/live" component={Live} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      {pagesAuth["3"] && <PrivateRoute path="/reports" component={Reports} />}
      {pagesAuth["4"] && <PrivateRoute path="/setting" component={Settings} />}
      {/* {pagesAuth["415"] && ( */}
      {pagesAuth["46"] && (
        <PrivateRoute path="/geofence" component={GeoFence} />
      )}
      {pagesAuth["16"] && <PrivateRoute path="/users" component={Users} />}
      {pagesAuth["182"] && <PrivateRoute path="/billing" component={Billing} />}
      {pagesAuth["17"] && (
        <PrivateRoute path="/vehicles" component={Vehicles} />
      )}
      <LazyRoute path="/login" component={Login} />
      <LazyRoute path="/share-location" component={ShareLocation} />
      <Redirect to="/login" />
    </Switch>
  )

  return <Suspense fallback={<Loader />}>{renderRoute()}</Suspense>
}

const mapStateToProps = (state) => ({
  pagesAuth: state.userProfile.pagesAuth,
})

export default connect(
  mapStateToProps,
  { fetchAlertNotification }
)(AppMain)
