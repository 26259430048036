import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import styled from "styled-components"
import swal from "sweetalert"
import { useTranslation } from "react-i18next"
import ReportsPopOver from "./ReportsPopOver"
import SettingsPopOver from "./SettingsPopOver"
import billing_0 from "../../../Assets/svgs/billing_0.svg"
import billing_1 from "../../../Assets/svgs/billing_1.svg"
import map_0 from "../../../Assets/svgs/map_0.svg"
import map_1 from "../../../Assets/svgs/map_1.svg"
import home_0 from "../../../Assets/svgs/home_0.svg"
import home_1 from "../../../Assets/svgs/home_1.svg"
import reports_0 from "../../../Assets/svgs/reports_0.svg"
import reports_1 from "../../../Assets/svgs/reports_1.svg"
import settings_0 from "../../../Assets/svgs/settings_0.svg"
import settings_1 from "../../../Assets/svgs/settings_1.svg"
import vehicle_0 from "../../../Assets/svgs/vehicle_0.svg"
import vehicle_1 from "../../../Assets/svgs/vehicle_1.svg"
import logout_0 from "../../../Assets/svgs/logout_0.svg"
import users_1 from "../../../Assets/svgs/users_1.svg"
import users_0 from "../../../Assets/svgs/users_0.svg"
import addGeofence0 from "../../../Assets/svgs/addGeofence0.svg"
import addGeofence1 from "../../../Assets/svgs/addGeofence1.svg"

const Button = styled.button`
  border-bottom: ${(props) =>
    props.active
      ? "4px solid var(--primary) !important"
      : "4px solid #f1f4f6 !important"};

  :hover {
    background: rgba(0, 0, 0, 0.05);
  }
`

const IconWrapper = styled.div`
  img {
    width: 28px;
  }
`

const FooterDots = ({
  history,
  location,
  pagesAuth,
  activeButton,
  updateActiveFooterbutton,
}) => {
  const [popoverOpen2, setPopoverOpen2] = useState(false)
  const [popoverOpenSetting, setPopoverOpenSetting] = useState(false)
  const [isActive, setIsActive] = useState({})
  const { t } = useTranslation()

  const toggle2 = (e) => setPopoverOpen2(!popoverOpen2)

  const toggleSetting = (e) => setPopoverOpenSetting(!popoverOpenSetting)

  const renderButton = (src, label, value, callback, id = null) => (
    <Button
      id={id}
      className="dot-btn-wrapper"
      active={activeButton === value}
      onClick={callback}
    >
      <IconWrapper>
        <img src={src} alt={src} />
      </IconWrapper>
      <p className={`${activeButton === value} && "text-primary"} mb-0`}>
        {label}
      </p>
    </Button>
  )

  const handleRedirectClick = (reportType) => {
    setPopoverOpen2(!popoverOpen2)
    updateActiveFooterbutton("reports")
    history.push(`/reports/${reportType.replace(/\s/g, "_")}`)
  }

  const handleRedirectSettings = (reportType) => {
    setPopoverOpenSetting(!popoverOpenSetting)
    updateActiveFooterbutton("settings")
    history.push(`/setting/${reportType.replace(/\s/g, "_")}`)
  }

  return (
    <>
      {renderButton(
        activeButton === "home" ? home_1 : home_0,
        t("HOME"),
        "home",
        () => {
          updateActiveFooterbutton("home")
          history.push("/dashboard")
        }
      )}
      <div className="dots-separator" />
      {renderButton(
        activeButton === "live" ? map_0 : map_1,
        t("LIVE"),
        "live",
        () => {
          updateActiveFooterbutton("live")
          history.push("/live")
        }
      )}
      <div className="dots-separator" />
      {pagesAuth["17"] &&
        renderButton(
          activeButton === "vehicles" ? vehicle_1 : vehicle_0,
          t("VEHICLES"),
          "vehicles",
          () => {
            updateActiveFooterbutton("vehicles")
            history.push("/vehicles")
          }
        )}
      <div className="dots-separator" />
      {pagesAuth["16"] &&
        renderButton(
          activeButton === "users" ? users_1 : users_0,
          t("USERS"),
          "users",
          () => {
            updateActiveFooterbutton("users")
            history.push("/users")
          }
        )}
      <div className="dots-separator" />
      {/* // {pagesAuth["415"] &&  */}
      {pagesAuth["46"] &&
        renderButton(
          activeButton === "geofence" ? addGeofence1 : addGeofence0,
          t("GEOFENCE"),
          "geofence",
          () => {
            updateActiveFooterbutton("geofence")
            history.push("/geofence")
          }
        )}
      <div className="dots-separator" />
      {pagesAuth["182"] &&
        renderButton(
          activeButton === "billing" ? billing_1 : billing_0,
          t("BILLING"),
          "billing",
          () => {
            updateActiveFooterbutton("billing")
            history.push("/billing")
          }
        )}
      <div className="dots-separator" />
      {renderButton(
        activeButton === "reports" ? reports_1 : reports_0,
        t("REPORTS"),
        "reports",
        toggle2,
        "PopoverFooter-2"
      )}
      <div className="dots-separator" />
      {renderButton(
        activeButton === "settings" ? settings_1 : settings_0,
        t("SETTINGS"),
        "settings",
        toggleSetting,
        "popoverOpenSetting"
      )}
      <div className="dots-separator" />
      {renderButton(
        activeButton === "logOut" ? logout_0 : logout_0,
        t("LOGOUT"),
        "logOut",
        () => {
          setIsActive({ logOut: !isActive.logOut })
          try {
            localStorage.clear()
            window.location.replace("/")
          } catch (ex) {
            console.log(ex)
            swal(t("UNEXPECTED_ERROR"))
          }
        }
      )}
      <ReportsPopOver
        t={t}
        pagesAuth={pagesAuth}
        toggle={toggle2}
        popoverOpen={popoverOpen2}
        onRedirectClick={handleRedirectClick}
      />
      {/* Must set target in --PopOver to id of PopUp */}
      <SettingsPopOver
        t={t}
        pagesAuth={pagesAuth}
        toggle={toggleSetting}
        popoverOpen={popoverOpenSetting}
        onRedirectClick={handleRedirectSettings}
      />
    </>
  )
}

const isEqual = (prevProps, nextProps) =>
  !(
    Object.keys(prevProps.pagesAuth).length !==
      Object.keys(nextProps.pagesAuth).length ||
    prevProps.activeButton !== nextProps.activeButton
  )

const MemoFooterDots = React.memo(FooterDots, isEqual)

const mapStateToProps = (state) => ({
  pagesAuth: state.userProfile.pagesAuth,
  activeButton: state.uiReducer.activeButton,
})

const mapDispatchToProps = (dispatch) => {
  return {
    updateActiveFooterbutton: (activeButton) =>
      dispatch({ type: "SET_ACTIVE_FOOTER_BTN", payload: { activeButton } }),
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MemoFooterDots)
)
