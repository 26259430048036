const devBaseUrl = "https://beta.georadius.in/"
const devDomainName = "beta.georadius.in"
// const devBaseUrl = "https://beta.gpsplatform.in/"
// const devDomainName = "beta.gpsplatform.in"

const baseUrl =
  process.env.NODE_ENV === "production"
    ? `${window.location.origin}/`
    : devBaseUrl

const domainName =
  process.env.NODE_ENV === "production"
    ? `${window.location.host}/`
    : devDomainName

export default {
  baseUrl,
  domainName,
  loginAction: "login",
}
