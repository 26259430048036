import * as type from "../types/types"

const initialState = {
  usersId: null,
  userList: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_USERS:
      return {
        ...state,
        userList: action.payload,
      }
    case type.UPDATE_USERS_ID:
      return {
        ...state,
        usersId: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}

// const userIdInitialState = {}
// export const usersIdReducer = (state = userIdInitialState, action) => {
//   console.log("userId-reducer:")
//   action.type === UPDATE_USERS_ID &&
//     console.log("userId-reducer-payload: ", action.payload)

//   switch (action.type) {
//     case UPDATE_USERS_ID: {
//       console.log("userid-Case Upadted")
//       return {
//         ...state,
//         ...action.payload
//       }
//     }
//     default:
//       return {
//         ...state
//       }
//   }
// }
// // console.log("initial-userid-state: ", userIdInitialState)
