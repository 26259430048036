import React from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import Icons from "../../../../../Pages/Icons/Examples/icons"
import { H5 } from "../../../../../Pages/Components/font"

const Wrapper = styled.div`
  width: 100%;
`

const BodyWrapper = styled.div`
  padding: 10px 20px;
`

const Header = styled.div`
  width: 100%;
  background: ${({ background }) => background};
  padding: 16px 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
`

const Card = styled.div`
  padding: 10px 20px;
  display: flex;
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  margin-top: 1.6rem;
  p {
    margin-left: 10px;
    margin-bottom: 0;
  }
`

export default ({ t }) => {
  const headerColor = useSelector(({ uiReducer }) => uiReducer.headerColor)

  return (
    <Wrapper>
      <Header background={headerColor}>
        <H5>{t("SERVICE_PROVIDER")}</H5>
      </Header>
      {/* <h4 className="text-center">{t("SERVICE_PROVIDER")}</h4> */}
      <BodyWrapper>
        <Card>
          <Icons icon="building" fill="var(--primary)" />
          <p>apps@nms.co.in</p>
        </Card>
        <Card>
          <Icons icon="user" fill="var(--success)" />
          <p>NMS-ALL</p>
        </Card>
        <Card>
          <Icons icon="phone" fill="var(--red)" />
          <p>+91 8123452672</p>
        </Card>
        <Card>
          <Icons icon="email" fill="var(--cyan)" />
          <p>info@nms.co.in</p>
        </Card>
      </BodyWrapper>
    </Wrapper>
  )
}
