import { FETCH_DEVICE_TYPE } from "../types/types"

const initialState = {
  deviceType: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEVICE_TYPE:
      return {
        ...state,
        deviceType: action.payload
      }
    default:
      return { ...state }
  }
}
