import { useState, useEffect } from "react"
import getMapBaseLayers from "./getMapBaseLayers"

const useMap = (mapDefCenter, L, callback) => {
  const [mapRef, setMapRef] = useState(null)

  useEffect(() => {
    let container = L.DomUtil.get("map")
    if (container != null) {
      container._leaflet_id = null
    }

    const map = new L.Map("map", {
      zoom: 14,
      center: mapDefCenter,
      zoomControl: false,
    })

    setMapRef(map)

    new L.Control.Zoom({ position: "bottomleft" }).addTo(map)

    let baseLayers = getMapBaseLayers(L)
    let layersControl = L.control.layers(baseLayers, null, {
      position: "bottomleft",
    })
    layersControl.addTo(map)
    map.addLayer(baseLayers.Map)

    map.dragging.enable()
    if (callback instanceof Function) callback(map)
  }, [])

  return mapRef
}

export default useMap
