import { UPDATE_DEVICE } from "../types/types"

const initialState = {
  selectedDevice: { device_id: null, registration_no: "" },
}

export default (state = initialState, action) => {
  // console.log(action)
  switch (action.type) {
    case UPDATE_DEVICE:
      return {
        ...state,
        selectedDevice: action.payload,
      }
    default:
      return { ...state }
  }
}
