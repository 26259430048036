import React from "react"
import styled from "styled-components"
import Loader from "react-loaders"

const LoaderWrapper = styled.div`
  background: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ subHeight }) =>
    subHeight ? `calc(100vh - ${subHeight})` : "100vh"};
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
`

export default ({ subHeight }) => (
  <LoaderWrapper subHeight={subHeight}>
    <Loader type="ball-grid-beat" />
  </LoaderWrapper>
)
