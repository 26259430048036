import React, { Component } from "react"
import styled from "styled-components"
import Icons from "../Icons/Examples/icons"

const FormGroup = styled.div`
  width: 100%;
  position: relative;
  z-index: 9;
`

const Label = styled.label`
  width: 100%;
  display: block;
  margin-bottom: 0;
  position: relative;

  span {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    color: darkcyan;
    letter-spacing: 1px;
    position: absolute;
    top: -8px;
    left: 32px;
    background: #fff;
    padding: 0 6px;
  }
`

const StyledInput = styled.input`
  width: 100%;
  -webkit-appearance: none;
  font-weight: ${(props) => (props.fontWt ? props.fontWt : "normal")};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : "1px"};
  color: var(--secondary);
  border-radius: 50px;
  border: 0;
  border: ${({ border }) => border && "1px solid darkcyan"};

  -webkit-opacity: 1;
  background: white;
  padding-left: 16px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: none;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    transition: background-color 5000s 0s;
  }

  &::-webkit-input-placeholder {
    // color: red;
    visibility: hidden;
  }
  &:-ms-input-placeholder {
    color: blue;
    // visibility: hidden;
  }
  &::-ms-input-placeholder {
    color: green;
    opacity: 0;
  }

  &:focus {
    outline: none;
    background: none !important;
  }
`

const Small = styled.small`
  display: block;
  font-size: 16px;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.54);
`

const Error = styled.p`
  color: red;
  font-size: 16px;
  margin-bottom: 0;
  position: absolute;
  bottom: -1rem;
  z-index: 9;
`

const InputWrapper = styled.div`
  display: flex;
  & > svg {
    margin-top: 4px;
    margin-left: -2rem;
  }
  & > :last-child {
    margin-left: ${(props) => (props.icon2 ? "-15rem" : null)};
  }
`

export default class Input extends Component {
  render() {
    const {
      small,
      type = "text",
      error,
      icon1,
      icon2,
      Readonly,
      placeholder,
      e_label,
      e_category,
      disabled,
      ...restProps
    } = this.props
    return (
      <FormGroup>
        <InputWrapper icon2={icon2}>
          <Label>
            <span>{placeholder}</span>
            <StyledInput
              type={type}
              readOnly={Readonly && true}
              disabled={disabled}
              border={disabled && "2px solid grey"}
              color={disabled ? "#4a4a4a" : "#009999"}
              {...restProps}
            />
          </Label>
          {icon1 && (
            <Icons
              icon={icon1.name}
              width={icon1.width}
              height={icon1.height}
              fill={icon1.fill ? icon1.fill : "#436ab2"}
            />
          )}
        </InputWrapper>
        {small && <Small>{small}</Small>}
        {error && <Error>{error}</Error>}
      </FormGroup>
    )
  }
}
