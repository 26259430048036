import swal from "sweetalert"
import * as type from "../types/types"
import {
  getVehicle,
  getVehicleHistory,
  getVehicleTrip,
  getVehicleDistance,
  getAlerts,
  getFleetUsage,
  getOverSpeed,
  getVehicleFuel,
  getVehicleTemp,
  getVehicleTripIgnition,
  getVehicleDigitalSensor,
  getVehicleType,
  getVehicleBrand,
  getVehicleMake,
  getAlertNotification,
} from "../../Services/vehicleService"

export const fetchVehicleAction = (usersId, path) => async (dispatch) => {
  try {
    const { data } = await getVehicle(usersId)

    return usersId
      ? dispatch({
          type: type.FETCH_USERS_VEHICLE,
          payload: data.data,
        })
      : dispatch({
          type: type.FETCH_VEHICLE,
          payload: data.data,
        })
  } catch (ex) {
    console.log(ex)
    swal(ex)
  }
}

export const fetchVehicleHistory = (deviceId, dateFrom, dateTo) => async (
  dispatch
) => {
  // alert("fetchVehicleHistory called! ", deviceId, " ", dateFrom, " ", dateTo)
  // console.log("fetchVehicleHistory called! ", deviceId)
  try {
    const key = localStorage.getItem("key")
    const user_name = localStorage.getItem("user_name")

    const { data } = await getVehicleHistory(
      key,
      user_name,
      deviceId,
      dateFrom,
      dateTo
    )

    if (!data.data) swal("Oops! 😐", data.message)
    return dispatch({
      type: type.FETCH_VEHICLE_HISTORY,
      payload: data.data,
    })
  } catch (ex) {
    console.log(ex)
    swal(ex)
  }
}

export const clearVehicleHistory = () => (dispatch) =>
  dispatch({
    type: type.CLEAR_VEHICLE_HISTORY,
    payload: null,
  })

export const fetchVehicleTrip = (
  deviceId,
  dateFrom,
  dateTo,
  timeFrom,
  timeTo
) => async (dispatch) => {
  // alert("fetchVehicleHistory called! ", deviceId, " ", dateFrom, " ", dateTo)
  try {
    const key = localStorage.getItem("key")
    const user_name = localStorage.getItem("user_name")

    const { data } = await getVehicleTrip(
      key,
      user_name,
      deviceId,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo
    )

    if (data.data.length === 0) swal("Oops! 😐", "No Trip Data Found.")
    return dispatch({
      type: type.FETCH_VEHICLE_TRIP,
      payload: data.data,
    })
  } catch (ex) {
    console.log(ex)
    swal(ex)
  }
}

export const fetchVehicleDistance = (
  deviceId,
  dateFrom,
  dateTo,
  timeFrom,
  timeTo
) => async (dispatch) => {
  // alert("fetchVehicleHistory called! ", deviceId, " ", dateFrom, " ", dateTo)
  try {
    const key = localStorage.getItem("key")
    const user_name = localStorage.getItem("user_name")

    const { data } = await getVehicleDistance(
      key,
      user_name,
      deviceId,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo
    )

    if (data.data && data.data.length === 0) swal("Oops! 😐", "No Data Found.")
    return dispatch({
      type: type.FETCH_VEHICLE_DISTANCE,
      payload: data.data,
    })
  } catch (ex) {
    console.log(ex)
    swal(ex)
  }
}

export const clearVehicleTrip = () => (dispatch) =>
  dispatch({
    type: type.CLEAR_VEHICLE_TRIP,
    payload: null,
  })

export const clearVehicleDistance = () => (dispatch) =>
  dispatch({
    type: type.CLEAR_VEHICLE_DISTANCE,
    payload: null,
  })

export const fetchVehicleAlert = (
  deviceId,
  dateFrom,
  dateTo,
  timeFrom,
  timeTo,
  alertTypeId
) => async (dispatch) => {
  try {
    const key = localStorage.getItem("key")
    const user_name = localStorage.getItem("user_name")

    const { data } = await getAlerts(
      key,
      user_name,
      deviceId,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
      alertTypeId
    )

    if (data.result === 1) swal("Oops! 😐", "No Alert Data Found.")
    return dispatch({
      type: type.FETCH_VEHICLE_ALERTS,
      payload: data.data,
    })
  } catch (ex) {
    console.log(ex)
    swal(ex)
  }
}

export const clearVehicleAlert = () => (dispatch) =>
  dispatch({
    type: type.CLEAR_VEHICLE_ALERTS,
    payload: null,
  })

export const fetchVehicleFleet = (
  deviceId,
  dateFrom,
  dateTo,
  timeFrom,
  timeTo
) => async (dispatch) => {
  try {
    const key = localStorage.getItem("key")
    const user_name = localStorage.getItem("user_name")

    const { data } = await getFleetUsage(
      key,
      user_name,
      deviceId,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo
    )

    if (data.result === 1) swal("Oops! 😐", "No Alert Data Found.")
    return dispatch({
      type: type.FETCH_VEHICLE_FLEET,
      payload: data.data,
    })
  } catch (ex) {
    console.log(ex)
    swal(ex)
  }
}

export const clearVehicleFleet = () => (dispatch) =>
  dispatch({
    type: type.CLEAR_VEHICLE_FLEET,
    payload: null,
  })

export const fetchVehicleOverSpeed = (
  deviceId,
  dateFrom,
  dateTo,
  timeFrom,
  timeTo,
  speedLimit
) => async (dispatch) => {
  try {
    const key = localStorage.getItem("key")
    const user_name = localStorage.getItem("user_name")

    const { data } = await getOverSpeed(
      key,
      user_name,
      deviceId,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
      speedLimit
    )

    console.log("Over-Speed-data: ", data.data)

    if (data.result === 1) swal("Oops! 😐", "No Over Speed Data Found.")
    return dispatch({
      type: type.FETCH_VEHICLE_OVER_SPEED,
      payload: data.data,
    })
  } catch (ex) {
    console.log(ex)
    swal(ex)
  }
}

export const clearVehicleOverSpeed = () => (dispatch) =>
  dispatch({
    type: type.CLEAR_VEHICLE_OVER_SPEED,
    payload: null,
  })

export const fetchVehicleFuel = (
  deviceId,
  dateFrom,
  dateTo,
  timeFrom,
  timeTo
) => async (dispatch) => {
  try {
    const key = localStorage.getItem("key")
    const user_name = localStorage.getItem("user_name")

    const { data } = await getVehicleFuel(
      key,
      user_name,
      deviceId,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo
    )

    if (
      data.result === 5 ||
      (data.data && data.data.fuel_data && data.data.fuel_data.length === 0)
    )
      swal("Oops! 😐", "No Fuel Data Found.")
    return dispatch({
      type: type.FETCH_VEHICLE_FUEL,
      payload: data.data,
    })
  } catch (ex) {
    console.log(ex)
    swal(ex)
  }
}

export const clearVehicleFuel = () => (dispatch) =>
  dispatch({
    type: type.CLEAR_VEHICLE_FUEL,
    payload: null,
  })

export const fetchVehicleTemp = (
  deviceId,
  dateFrom,
  dateTo,
  timeFrom,
  timeTo
) => async (dispatch) => {
  try {
    const key = localStorage.getItem("key")
    const user_name = localStorage.getItem("user_name")

    const { data } = await getVehicleTemp(
      key,
      user_name,
      deviceId,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo
    )

    if (
      data.result === 5 ||
      (data.data && data.data.fuel_data && data.data.fuel_data.length === 0)
    )
      swal("Oops! 😐", "No Fuel Data Found.")
    return dispatch({
      type: type.FETCH_VEHICLE_TEMP,
      payload: data.data,
    })
  } catch (ex) {
    console.log(ex)
    swal(ex)
  }
}

export const clearVehicleTemp = () => (dispatch) =>
  dispatch({
    type: type.CLEAR_VEHICLE_TEMP,
    payload: null,
  })

export const fetchVehicleTripIgnition = (
  deviceId,
  dateFrom,
  dateTo,
  timeFrom,
  timeTo
) => async (dispatch) => {
  try {
    const key = localStorage.getItem("key")
    const user_name = localStorage.getItem("user_name")

    const { data } = await getVehicleTripIgnition(
      key,
      user_name,
      deviceId,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo
    )

    if (
      data.result === 5 ||
      (data.data && data.data.fuel_data && data.data.fuel_data.length === 0)
    )
      swal("Oops! 😐", "No Fuel Data Found.")
    return dispatch({
      type: type.FETCH_VEHICLE_TRIP_IGNITION,
      payload: data.data,
    })
  } catch (ex) {
    console.log(ex)
    swal(ex)
  }
}

export const clearVehicleTripIgnition = () => (dispatch) =>
  dispatch({
    type: type.CLEAR_VEHICLE_TRIP_IGNITION,
    payload: null,
  })

export const fetchVehicleDigitalSensor = (
  deviceId,
  dateFrom,
  dateTo,
  timeFrom,
  timeTo,
  sensorType,
  sensorValue
) => async (dispatch) => {
  try {
    const key = localStorage.getItem("key")
    const user_name = localStorage.getItem("user_name")

    const { data } = await getVehicleDigitalSensor(
      key,
      user_name,
      deviceId,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
      sensorType,
      sensorValue
    )

    if (
      data.result === 5 ||
      data.result === 4 ||
      (data.data && data.data.json_data && data.data.json_data.length === 0)
    )
      swal("Oops! 😐", "No Sensor Data Found.")
    return dispatch({
      type: type.FETCH_VEHICLE_DIGITAL_SENSOR,
      payload: data.data,
    })
  } catch (ex) {
    console.log(ex)
    swal(ex)
  }
}

export const clearVehicleDigitalSensor = () => (dispatch) =>
  dispatch({
    type: type.CLEAR_VEHICLE_DIGITAL_SENSOR,
    payload: null,
  })

export const fetchVehicleType = () => async (dispatch) => {
  try {
    const user_name = localStorage.getItem("user_name")
    const hash_key = localStorage.getItem("key")

    const { data } = await getVehicleType(user_name, hash_key)
    return dispatch({
      type: type.FETCH_VEHICLE_TYPE,
      payload: data.data,
    })
  } catch (ex) {
    swal("Some Error occurred!", ex)
  }
}

export const fetchVehicleBrand = () => async (dispatch) => {
  try {
    const user_name = localStorage.getItem("user_name")
    const hash_key = localStorage.getItem("key")

    const { data } = await getVehicleBrand(user_name, hash_key)
    return dispatch({
      type: type.FETCH_VEHICLE_BRAND,
      payload: data.data,
    })
  } catch (ex) {
    swal("Some Error occurred!", ex)
  }
}

export const fetchVehicleMake = () => async (dispatch) => {
  try {
    const user_name = localStorage.getItem("user_name")
    const hash_key = localStorage.getItem("key")

    const { data } = await getVehicleMake(user_name, hash_key)
    return dispatch({
      type: type.FETCH_VEHICLE_MAKE,
      payload: data.data,
    })
  } catch (ex) {
    swal("Some Error occurred!", ex)
  }
}

export const fetchAlertNotification = (userId) => async (dispatch) => {
  try {
    const { data } = await getAlertNotification(userId)
    // console.log("User-alert: ", data)
    if (data.result === 0) {
      return dispatch({
        type: type.FETCH_ALERT_NOTIFICATION,
        payload: data.data,
      })
    }
  } catch (ex) {
    console.log(ex.message)
    swal("Some Error occurred!")
  }
}
