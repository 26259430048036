import React from "react"
import Icons from "../Icons/Examples/icons"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ padding }) => (padding ? padding : "4px 6px 4px 4px")};
  border-radius: 20px;
  border: 1px solid darkcyan;
  cursor: pointer;
  p {
    color: ${({ active }) => (active ? "var(--primary)" : "var(--secondary)")};
    margin-bottom: 0;
  }
`

const CircleWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Circle = styled.div`
  width: 19px;
  height: 19px;
  border: 2px solid var(--secondary);
  border-radius: 50%;
`

export default ({ active, label, onClick, padding }) => {
  return (
    <Wrapper onClick={onClick} active={active} padding={padding}>
      {active ? (
        <div>
          <Icons icon="unCheckCircle" fill="#545cd8" />
        </div>
      ) : (
        <CircleWrapper>
          <Circle />
        </CircleWrapper>
      )}
      <p>{label}</p>
    </Wrapper>
  )
}
