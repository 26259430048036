import http from "./httpService"
import axios from "axios"
import config from "../config"

export const getPagesAuth = (user_type_id) =>
  http.get("menu_permissions_result.php", {
    params: {
      action: "menuAssignedUser",
      user_type_id,
    },
  })

export const getUsers = (
  user_name,
  hash_key,
  group_id,
  username,
  phone,
  email,
  name,
  user_type,
  userid
) => {
  return http.get("user_result.php", {
    params: {
      action: "search_user_json",
      user_name,
      hash_key,
      group_id,
      username,
      phone,
      email,
      name,
      user_type,
      userid,
    },
  })
}

export const getUserType = (user_name, hash_key) =>
  http.get("user_result.php", {
    params: {
      action: "user_type_search",
      data_format: 1,
      user_name,
      hash_key,
    },
  })

//   user_name,
//   hash_key,
//   group_type,
//   username,
//   phone,
//   email,
//   password,
//   address,
//   company_name,
//   user_type
export const postUser = (
  user_name,
  hash_key,
  group_type,
  name,
  phone,
  email,
  password,
  address,
  company_name,
  user_type,
  action = "add",
  user_id
) =>
  http.get("user_result.php", {
    params: {
      action,
      data_format: 1,
      user_name,
      hash_key,
      group_type,
      username: email,
      password,
      email,
      phone,
      company_name,
      address,
      user_type,
      name,
      user_id,
      // gst_no,
    },
  })

export const updateProfile = (
  group_type,
  user_type,
  user_id,
  map_zoom_level,
  page_refresh_interval,
  format_date,
  map_default_position,
  columnPermissions
) =>
  http.get("user_result.php", {
    params: {
      action: "update",
      data_format: 1,
      group_type,
      user_type,
      user_id,
      map_zoom_level,
      page_refresh_interval,
      format_date,
      map_default_position,
      ...columnPermissions,
    },
  })

export const resetUserPassword = (user_id, password, user_name, hash_key) =>
  http.get("/user_result.php", {
    params: {
      action: "resetpassword",
      data_format: 1,
      user_id,
      password,
      user_name,
      hash_key,
    },
  })

export const changeUserPassword = (
  user_id,
  current_password,
  confirm_password,
  new_password,
  user_name,
  hash_key
) => {
  const params = {
    action: "change_password",
    data_format: 1,
    user_id,
    current_password,
    confirm_password,
    new_password,
    user_name: localStorage.getItem("user_name"),
    hash_key: localStorage.getItem("key"),
  }

  return http.get("/user_result.php", { params })
}

export const updateVehicleAssignment = (
  user_id,
  status,
  device_id,
  user_name,
  hash_key
) =>
  http.get("/user_result.php", {
    params: {
      action: "vehicleassign",
      data_format: 1,
      user_id,
      status,
      device_id,
      user_name,
      hash_key,
    },
  })

export const reminderSearch = (
  device_id,
  activityName,
  reminder_based_on,
  date_from,
  date_to,
  time_picker_from,
  time_picker_to,
  date_status
) =>
  http.get("reminder_result.php", {
    params: {
      action: "reminder_search",
      data_format: 1,
      reminder_text: activityName,
      device_id,
      reminder_based_on,
      date_from,
      date_to,
      time_picker_from,
      time_picker_to,
      date_status,
    },
  })
//day-0 km-1
export const reminderAdd = (
  deviceId,
  activityType,
  notification,
  reminderType,
  dueDate,
  dueTime,
  freqType,
  nextDueKm,
  reminderKmDist,
  alertCutOff
) =>
  http.get("reminder_result.php", {
    params: {
      action: "add_reminder",
      data_format: 1,
      device_id: deviceId,
      reminder_text: activityType,
      reminder_notification: notification,
      reminder_based_on: reminderType,
      reminder_next_due_date: dueDate,
      reminder_next_due_time: dueTime,
      reminder_interval: freqType,
      next_due_km: nextDueKm,
      reminder_km_value: reminderKmDist,
      alert_notification_cutoff: alertCutOff,
    },
  })

export const reminderDelete = (reminder_id) =>
  http.get("reminder_result.php", {
    params: {
      action: "delete",
      data_format: 1,
      reminder_id,
    },
  })

export const getLicenseBasis = () =>
  http.get("billing_api.php", {
    params: {
      action: "renew_licence_details",
    },
  })

export const getQuotaResult = (deviceCount, billingTerm, deviceIds) =>
  http.get("vehicle_quota_result.php", {
    params: {
      action: "edit_device_quota",
      device_count: deviceCount,
      billing_term: billingTerm,
      device_ids: deviceIds,
    },
  })

export const addNewLicense = (licence_quantity, billingTerm) =>
  http.get("vehicle_quota_result.php", {
    params: {
      action: "add_device_quota",
      licence_quantity,
      billing_term: billingTerm,
    },
  })

export const getPaymentOrder = (formData) =>
  axios({
    method: "post",
    url: `${config.baseUrl}billing_api.php`,
    data: formData,
    headers: {
      "content-type": "multipart/form-data",
    },
  })

export const getBillingStatement = (
  user_id,
  date_from,
  date_to,
  time_picker_from,
  time_picker_to
) =>
  http.get("billing_api.php", {
    params: {
      action: "statement_generate",
      data_format: 1,
      user_id,
      date_from,
      date_to,
      time_picker_from,
      time_picker_to,
    },
  })

export const printInvoice = (invoice_id, user_id) =>
  http.get("invoice_print.php", {
    params: {
      invoice_id,
      user_id,
    },
  })

export const getUserNotification = (user_id) =>
  http.get("user_alert_permissions_result.php", {
    params: { action: "user_alert_search", data_format: 1, user_id },
  })

export const deleteUserNotification = (user_id, useralerttype_id) =>
  http.get("/user_alert_permissions_result.php", {
    params: {
      action: "delete_useralerttype",
      data_format: 1,
      user_id,
      useralerttype_id,
    },
  })

export const addUserAlertNotification = (
  alert_type_id,
  sms_notification,
  email_notification,
  app_notification,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
  active_time_start,
  active_time_end,
  email,
  phone
) =>
  http.get("/user_alert_permissions_result.php", {
    params: {
      action: "alertassignment",
      data_format: 1,
      alert_type_id,
      sms_notification,
      email_notification,
      app_notification,
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      active_time_start,
      active_time_end,
      email,
      phone,
    },
  })
