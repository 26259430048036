import React, { Suspense } from "react"
import Hamburger from "react-hamburgers"
import PerfectScrollbar from "react-perfect-scrollbar"
import Drawer from "react-motion-drawer"
import LeftDrawer from "./TabsContent/leftDrawer"

const LeftSideBar = ({ openLeft, onChangeOpenLeft }) => (
  <Suspense fallback={<>...</>}>
    <Drawer
      className="drawer-content-wrapper p-0"
      width={260}
      paddingLeft={0}
      open={openLeft}
      onChange={(open) => onChangeOpenLeft(open)}
      noTouchOpen={false}
      noTouchClose={false}
    >
      <PerfectScrollbar>
        <div className="justify-content-between drawer-nav-btn drawer-nav-btn_left">
          <Hamburger
            active={openLeft}
            type="elastic"
            onClick={() => onChangeOpenLeft(false, false)}
          />
        </div>
        <LeftDrawer />
      </PerfectScrollbar>
    </Drawer>
  </Suspense>
)

export default LeftSideBar
