import React from "react"
import { Redirect } from "react-router-dom"
import { useDispatch } from "react-redux"
import LazyRoute from "./lazyRoute"
import AppHeader from "../AppHeader"
import AppFooter from "../AppFooter"

export default function PrivateRoute(props) {
  const dispatch = useDispatch()
  // console.log(props.location.pathname)
  const page = props.location.pathname

  let isActive = ""

  if(page === '/dashboard') isActive = "home"
  else if(page === '/live') isActive = "live"
  else if(page === '/vehicles') isActive = "vehicles"
  else if(page === '/users')  isActive = "users"
  else if(page === '/geofence')  isActive = "geofence"
  else if(page === '/billing')  isActive = "billing"
  else if(page.includes('/reports'))  isActive = "reports"
  else if(page.includes('/setting'))  isActive = "settings"

  dispatch({type: 'SET_ACTIVE_FOOTER_BTN', payload: {activeButton: isActive}})
  if (localStorage.getItem("key"))
    return (
      <>
        <AppHeader />
        <LazyRoute {...props} />
        <AppFooter />
      </>
    )
  return <Redirect to="/" />
}
