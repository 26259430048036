import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { persistStore } from "redux-persist"
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly"

import rootReducer from "./reducers"

const initialState = {}

const middleware = [thunk]

const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
})

export const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
)
export const persistor = persistStore(store)
