import React, { useState } from "react"
import styled from "styled-components"
// import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Button } from "reactstrap"
import swal from "sweetalert"
import { sendFeedback } from "../../../../../Services/feedbackService"
import Icons from "../../../../../Pages/Icons/Examples/icons"
import { H5 } from "../../../../../Pages/Components/font"

const Wrapper = styled.div`
  width: 100%;
  // padding-top: 2rem;
  height: 100%;
  position: relative;
`
const BodyWrapper = styled.div`
  padding: 10px 20px;
`

const Header = styled.div`
  width: 100%;
  background: ${({ background }) => background};
  padding: 16px 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
`

const Card = styled.div`
  padding: 10px 20px;
  display: flex;
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  margin-top: 1.6rem;
  p {
    margin-bottom: 0;
  }
`

const DropDown = styled(Card)`
  justify-content: space-between;
`

const FeedbackTypeWrapper = styled.div`
  position: relative;
`

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px 20px;
  margin-top: 1.6rem;
  outline: 0;
  border: 0;
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  color: #495057 !important;
`

const Ul = styled.ul`
  width: 100%;
  background: #fff;
  list-style: none;
  margin-bottom: 0;
  padding: 0 !important;
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 100;
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

  li {
    padding: 0 10px;
    p {
      margin-bottom: 0 !important;
      padding: 6px 10px !important;
      border-bottom: 1px solid #dee2e6;
    }
    :hover {
      background: rgb(224, 243, 255);
    }
  }

  li:last-child {
    p {
      border: 0;
    }
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-bottom: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;

  button {
    width: 100%;
  }
`

const feedbackType = [
  { id: 1, label: "app Crash related" },
  { id: 2, label: "vehicle location related" },
  { id: 3, label: "vehicle tracking related" },
  { id: 4, label: "vehicle report related" },
  { id: 4, label: "payment related" },
]

export default ({ t, onClose }) => {
  const [selectFeedbackType, setSelectFeedbackType] = useState("")
  const [descText, setDescText] = useState("")
  const [openList, setOpenList] = useState(false)
  const headerColor = useSelector(({ uiReducer }) => uiReducer.headerColor)

  const handleFeedBack = async () => {
    try {
      const userName = localStorage.getItem("user_name")
      const hashKey = localStorage.getItem("key")
      const userId = localStorage.getItem("user_id")
      const formData = new FormData()
      formData.set("app_name", "gps_platform")
      formData.set("action", "feedback_submit")
      formData.set("issue_type", selectFeedbackType)
      formData.set("issue_desc", descText)
      formData.set("user_id", userId)
      formData.set("user_name", userName)
      formData.set("hash_key", hashKey)

      const { data } = await sendFeedback(formData)
      // console.log(data)
      if (data.result === 0) {
        swal(t("THANKYOU"), t("FEEDBACK_SUBMITTED"), "success").then(
          (value) => {
            if (value) return onClose()
          }
        )
        // swal(t("THANKYOU"), "You clicked the button!", "success")
      }
    } catch (ex) {
      swal(t("UNEXPECTED_ERROR"))
    }
  }

  return (
    <Wrapper>
      <Header background={headerColor}>
        <H5>{t("YOUR_FEEDBACK")}</H5>
      </Header>
      {/* <h4 className="text-center">{t("YOUR_FEEDBACK")}</h4> */}
      <BodyWrapper>
        <DropDown onClick={() => setOpenList(true)}>
          <p>
            {selectFeedbackType ? selectFeedbackType : t("SELECT_ISSUE_TYPE")}
          </p>
          <Icons icon="arrowDown" />
        </DropDown>
        <FeedbackTypeWrapper>
          {openList && (
            <Ul>
              {feedbackType.map((ftype) => (
                <li
                  key={ftype.id}
                  onClick={() => {
                    setSelectFeedbackType(ftype.label)
                    setOpenList(false)
                  }}
                >
                  <p>{ftype.label}</p>
                </li>
              ))}
            </Ul>
          )}
        </FeedbackTypeWrapper>
        <TextArea
          rows="4"
          placeholder={t("DESCRIPTION")}
          value={descText}
          onChange={(e) => setDescText(e.target.value)}
        ></TextArea>
        <ButtonWrapper>
          <Button
            className="btn-hover-shine btn-pill"
            color="primary"
            onClick={handleFeedBack}
          >
            {t("SUBMIT")}
          </Button>
        </ButtonWrapper>
      </BodyWrapper>
    </Wrapper>
  )
}
